var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"DxpPageFooter"},[_c('div',{staticClass:"DxpPageFooter__PreCard"},[_vm._t("precard")],2),_c('div',{staticClass:"DxpPageFooter__Content"},[_c('mom-layout-wrapper',[_c('a',{staticClass:"DxpPageFooter__Logo",attrs:{"href":"/","rel":"Ministry of Manpower"}},[_c('figure',{staticClass:"DxpPageFooter__LogoImage",domProps:{"innerHTML":_vm._s(
            _vm.getImgSvg(
              'footer_logo.svg',
              'Dx+ Logo',
              '150',
              '70',
              '251',
              '101'
            )
          )}})]),_c('mom-grid-container',{staticClass:"DxpPageFooter__Sitemap",attrs:{"gutter-size":"none"}},_vm._l((_vm.sitemap),function(item,index){return _c('mom-grid-column',{key:("sitemap_nav_" + index),attrs:{"size":"12","size-sm":"12","size-md":"4","size-lg":"4","size-xl":"4"}},[_c('div',[_c('mom-link',{staticClass:"DxpPageFooter__SitemapList DxpPageFooter__Link",attrs:{"href":item.href,"disabled":item.isDisabled},on:{"click":function($event){return _vm.checkIfSameRoute(item.href)}}},[_c('p',{staticClass:"mom-p"},[_vm._v(_vm._s(item.headerTitle))])]),_c('br'),_vm._l((item.options),function(link,i){return _c('div',{key:("sitemap_nav_item_" + (item.title) + "_" + i)},[_c('mom-link',{staticClass:"DxpPageFooter__SitemapListItem DxpPageFooter__Link",attrs:{"href":link.path,"disabled":link.isDisabled},on:{"click":function($event){return _vm.checkIfSameRoute(link.path)}}},[_c('p',{staticClass:"DxpPageFooter__SitemapListItemText"},[_vm._v(" "+_vm._s(link.title)+" ")])]),_c('br')],1)})],2)])}),1),_c('div',{staticClass:"DxpPageFooter__Others"},_vm._l((_vm.footerOthers),function(option,i){return _c('mom-link',{key:("footer_others_" + i),staticClass:"DxpPageFooter__Link DxpPageFooter__OtherLink",attrs:{"size":"m","href":option.href,"disabled":option.isDisabled,"icon":option.icon,"icon-position":option.iconPosition,"target":option.target,"rel":option.rel},on:{"click":function($event){return _vm.checkIfSameRoute(option.href)}}},[_c('p',{staticClass:"DxpPageFooter__OthersItemText"},[_vm._v(" "+_vm._s(option.text)+" ")])])}),1)],1)],1),_c('mom-page-footer',{attrs:{"slot":"footer","type":"infoServices","last-updated-date":"02 May 2023","links":_vm.momFooterSitemap},slot:"footer"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }