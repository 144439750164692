import DOMPurify from "dompurify"
import sqlEscapeString from 'sql-escape-string'
import get from 'lodash.get'

export default function(string = '', config) {
  const _domPurify = !!get(config, "dompurify", true)
  const _sqlEscapeString = !!get(config, "sql-escape-string", true)
  if (_sqlEscapeString) {
    string = sqlEscapeString(string)
    string = string.slice(1, string.length - 1)
  }
  if (_domPurify)
    string = DOMPurify.sanitize(string)
  return string
}