<template>
  <div class="DxpPage" @dragover="onDragover" @drop="onDrop">
    <a class="DxpPage__SkipLink" href="#main">Skip to content</a>
    <div class="DxpPage__Header">
      <!-- @slot Header content -->
      <slot name="header"></slot>
    </div>

    <div
      id="main"
      :class="[
        'DxpPage__Main',
        verticalCenteredContent && 'DxpPage__Main--vertical-centered',
        !isHome && 'DxpPage__Main--background',
      ]"
    >
      <mom-layout-wrapper>
        <div v-if="showBreadcrumb">
          <mom-breadcrumb
            v-if="breadcrumb && breadcrumb.length > 0"
            class="DxpPage__Breadcrumb"
            :links="breadcrumb"
          />
        </div>
        <h2 v-if="title" class="mom-h2 DxpPage__Title">{{ title }}</h2>
      </mom-layout-wrapper>
      <!-- @slot Main Content -->
      <slot></slot>
    </div>

    <div class="DxpPage__Footer">
      <!-- @slot Footer content -->
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { MomLayoutWrapper } from "cdpl/layout.esm";
import { MomBreadcrumb } from "cdpl/breadcrumb.esm";

export default {
  name: "DxpPage",
  release: "0.1.1",
  lastUpdated: "0.1.1",
  components: {
    MomLayoutWrapper,
    MomBreadcrumb,
  },
  props: {
    /**
     * Show breadcrumb in main page
     */
    showBreadcrumb: {
      type: Boolean,
      default: false,
    },

    /**
     * breadcrumb links <br />
     * `{ text, href, target, action, rel }`
     */
    breadcrumb: {
      type: Array,
    },

    /**
     * Whether the content should be vertically center-aligned
     */
    verticalCenteredContent: {
      type: Boolean,
      default: false,
    },

    /**
     * Page title
     */
    title: {
      type: String,
      default: "",
    },
    /**
     * If home page
     */
    isHome: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onDragover(e) {
      e.preventDefault();
    },

    onDrop(e) {
      e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.DxpPage {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  background-color: $color-cool-grey-00;
  overflow: hidden;
}

.DxpPage__SkipLink {
  @include font-size(s);
  @include line-height(s);
  position: absolute;
  left: rem(-625);
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: rem(8);
  background-color: $color-cool-grey-90;
  color: $color-white;
  border: 0;
  height: rem(1);
  width: rem(1);
  overflow: hidden;

  &:focus {
    position: fixed;
    top: 0;
    left: 0;
    clip: auto;
    height: auto;
    width: auto;
  }
}

.DxpPage__Header {
  flex: none;
}

.DxpPage__Breadcrumb {
  padding-bottom: rem(26);
}

.mom-h2.DxpPage__Title {
  margin-bottom: rem(24);
}

.DxpPage__Main {
  display: block;
  flex: 1 0 auto;
  padding-bottom: rem(48);
  padding-top: rem(16);

  &--background {
    background-image: url("../../assets/images_local/main_content_bg.png");
    background-repeat: no-repeat;
    background-position: left bottom;
    background-attachment: fixed;
    background-size: 80%;

    @include min-width(tablet) {
      background-attachment: scroll;
      background-size: 20%;
    }
  }

  &--vertical-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media print {
    display: block;
  }

  @include min-width(tablet) {
    padding-top: rem(85);
    padding-left: rem(16);
  }
}

.DxpPage__Footer {
  flex: none;

  @media print {
    display: none;
  }
}
</style>
