/**
 * @mixin
 * Define list of available actions for loading image
 */

export default {
  methods: {
    getImgUrl(url, altText = "", width, height) {
      if (width && height)
        return `<img src="./images_local/${url}" alt="${altText}" style="width: ${width}; height: ${height}"/>`;
      else
        return `<img src="./images_local/${url}" alt="${altText}" style="width: 100%; height: 100%"/>`;
    },
    getImgSvg(url, altText = "", width, height, viewBoxWidth, viewBoxHeight) {
      if (width && height) {
        if (!viewBoxWidth) viewBoxWidth = width;
        if (!viewBoxHeight) viewBoxHeight = height;
        return `<img svg-inline src="./images_local/${url}" alt="${altText}" viewBox="0 0 ${viewBoxWidth} ${viewBoxHeight}" style="width: ${width}px; height: ${height}px"/>`;
      } else
        return `<img svg-inline src="./images_local/${url}" alt="${altText}" style="width: 100%; height: 100%"/>`;
    },
  },
};
