<template>
  <div id="app">
    <div id="nav"></div>
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
export default {
  created() {
    /* ie 11 route navigation fix */
    let isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    if (
      "-ms-scroll-limit" in document.documentElement.style &&
      "-ms-ime-align" in document.documentElement.style
    ) {
      if (isIE11)
        window.addEventListener(
          "hashchange",
          (event) => {
            var currentPath = window.location.hash.slice(1);
            if (this.$route.path !== currentPath) {
              this.$router.push(currentPath);
            }
          },
          false
        );
    }
  },
};
</script>

<style>
html,
body {
  max-width: 100%;
}
</style>
