<template>
  <header
    :class="[
      'DxpPageHeader',
      'DxpPageHeader--is-sticky',
      showSearchOption && 'DxpPageHeader--show-search-bar',
      showSearchOption && 'DxpPageHeader--show-tabs',
    ]"
  >
    <div class="MomPageHeader__MastheadWrapper" aria-label="A Singapore Government Agency Website">
      <mom-layout-wrapper class="MomPageHeader__Masthead">
        <a
          class="MomPageHeader__MastheadLink"
          href="https://www.gov.sg/"
          target="_blank"
          rel="Singapore Government"
        >
          <figure
            style="width: 16px; height: 19px; align-self: center;"
            v-html="GovLogo"
          ></figure>
          <span class="MastheadDescriptor">
            A Singapore Government Agency Website
          </span>
        </a>
        <a
          class="MomPageHeader__MastheadLink"
          @click="toggleIdentifyPanel"
        >
          <span class="IdentifyLink">
            How to identify
          </span>
          <figure
            v-if="!isExpandedIdentifyPanel"
            class="ChevronStyle"
            v-html="ChevronDown"
          />
          <figure
            v-else
            class="ChevronStyle"
            v-html="ChevronUp"
          />
        </a>
      </mom-layout-wrapper>
    </div>

    <div 
      v-if="isExpandedIdentifyPanel" 
      class="MomPageHeader__MastheadWrapper"
      style="width: 100%; padding-top:32px;"
    >
      <mom-layout-wrapper class="MomPageHeader__Masthead">
        <div class="GovExpandedHeader">
          <div class="GovExpandedDiv">
            <div style="margin-right: 8px;">
              <figure style="width: 24px; height: 24px; align-self: center;" v-html="TownHall"/>
            </div>
            <div style=" display: inline-block;">
              <span style="margin-bottom: 8px;" class="GovExpandedFont BoldFont">Official website links end with .gov.sg</span>
              <span class="GovExpandedFont">Government agencies communicate via <span class="BoldFont">.gov.sg</span> websites (e.g. go.gov.sg/open). <a class="GovExpandedLinkFont" href="https://go.gov.sg/trusted-sites" target="_blank">Trusted websites <figure style="width: 18px; height: 18px; display: inline-block;" v-html="PopOut"/></a></span>
            </div>
          </div>
          <div class="GovExpandedDiv">
            <div style="margin-right: 8px;">
              <figure style="width: 24px; height: 24px; display: inline-block; align-self: center;" v-html="LockIcon"/>
            </div>
            <div>
              <span style="margin-bottom: 8px;" class="GovExpandedFont BoldFont">Secure websites use HTTPS</span>
              <span class="GovExpandedFont">Look for a <span class="BoldFont">lock</span> (<figure style="width: 18px; height: 18px; display: inline-block;" v-html="LockIcon"/>) or https:// as an added precaution. Share sensitive information only on official, secure websites.</span>
            </div>
          </div>
        </div>
      </mom-layout-wrapper>
    </div>

    <div class="DxpPageHeader__MainWrapper">
      <mom-layout-wrapper class="DxpPageHeader__Main">
        <a class="DxpPageHeader__Logo" href="/" rel="Ministry of Manpower">
          <figure
            class="DxpPageHeader__LogoImage"
            v-html="
              getImgSvg(
                'dxplus_logo_new.svg',
                'DX+ Digital Experience+',
                '160',
                '86',
                '1305',
                '701'
              )
            "
          />
        </a>

        <div class="DxpPageHeader__Tabs DxpPageHeader__Tabs--tablet">
          <template v-for="(tab, index) in tabs">
            <div
              :key="index"
              :id="`doc_header_tab_${index}`"
              :class="[
                'DxpPageHeader__Tab',
                index === activeTabIndexNumber &&
                  'DxpPageHeader__Tab--is-active',
              ]"
            >
              <a
                v-if="!tab.children"
                class="DxpPageHeader__Link DxpPageHeader__TabLink"
                :href="tab.pageUrl"
              >
                {{ tab.title }}
              </a>
            </div>
          </template>
          <div
            v-if="!!moreOptions"
            :class="[
              'DxpPageHeader__Tab',
              'DxpPageHeader__Dropdown',
              showMore && 'DxpPageHeader__Dropdown--show-content',
              activeTabIndexNumber === moreTabIndex &&
                'DxpPageHeader__Tab--is-active',
            ]"
          >
            <button
              class="DxpPageHeader__DropdownToggle"
              @click.prevent="onToggleMore"
              @keydown="onKeyDownMore"
              v-clickOutside="closeSearch"
            >
              {{ moreOptions.title }}
              <mom-icon icon="chevron-down" />
            </button>
            <div class="DxpPageHeader__DropdownContent">
              <a
                v-for="(link, index) in moreOptions.children"
                :key="index"
                :href="link.pageUrl"
                class="DxpPageHeader__Link DxpPageHeader__DropdownLink"
              >
                {{ link.title }}
              </a>
            </div>
          </div>
        </div>

        <div class="DxpPageHeader__Search">
          <button
            class="DxpPageHeader__SearchToggle"
            @click="onToggleSearch"
            @keydown="onKeyDownSearch"
            aria-label="Search"
          >
            <mom-icon
              class="DxpPageHeader__SearchToggle DxpPageHeader__SearchToggle--tablet"
              :icon="showSearchOption ? 'close' : 'search'"
            />
            <mom-icon
              class="DxpPageHeader__SearchToggle DxpPageHeader__SearchToggle--mobile"
              :icon="showSearchOption ? 'close' : 'menu'"
            />
          </button>
        </div>
      </mom-layout-wrapper>
      <mom-horizontal-line class="DxpPageHeader__Separator" />
      <mom-layout-wrapper>
        <div class="DxpPageHeader__SearchBar">
          <mom-input-text
            idForInput="DxpPageHeader__SearchInput"
            class="DxpPageHeader__SearchInput"
            placeholder="What are you looking for?"
            v-model="searchValue"
            @enter="onSearch"
            size="full"
          />
          <mom-button
            class="DxpPageHeader__SearchButton DxpPageHeader__SearchButton--tablet DxpButton"
            @click="onSearch"
            text="Search"
          />

          <mom-button
            class="DxpPageHeader__SearchButton DxpPageHeader__SearchButton--mobile DxpButton"
            @click="onSearch"
            icon="search"
            hide-text
          />
        </div>

        <div class="DxpPageHeader__Tabs DxpPageHeader__Tabs--mobile">
          <template v-for="(tab, index) in tabs">
            <div
              :key="index"
              :id="`doc_header_tab_mobile_${index}`"
              :class="[
                'DxpPageHeader__Tab',
                index === activeTabIndexNumber &&
                  'DxpPageHeader__Tab--is-active',
              ]"
            >
              <a
                class="DxpPageHeader__Link DxpPageHeader__TabLink"
                :href="tab.pageUrl"
              >
                {{ tab.title }}
              </a>
            </div>
          </template>
          <button
            v-if="!!moreOptions"
            class="DxpPageHeader__Tab DxpPageHeader__DropdownToggle--mobile"
            @click.prevent="onToggleMoreMobile"
          >
            {{ moreOptions.title }}
            <mom-icon icon="chevron-down" />
          </button>
          <div :class="['DxpPageHeader__DropdownContent--mobile']">
            <template v-for="(tab, index) in moreOptions.children">
              <div
                :key="index"
                :id="`doc_header_tab_mobile_${index}`"
                :class="[
                  'DxpPageHeader__Tab DxpPageHeader__TabChild',
                  index === activeTabIndexNumber &&
                    'DxpPageHeader__Tab--is-active',
                ]"
              >
                <a
                  class="DxpPageHeader__Link DxpPageHeader__TabLink"
                  :href="tab.pageUrl"
                >
                  {{ tab.title }}
                </a>
              </div>
            </template>
          </div>
        </div>
      </mom-layout-wrapper>
    </div>
  </header>
</template>

<script>
import DOMPurify from "dompurify";
import isInteger from "lodash.isinteger";
import KEYCODES from "@/constants/keycodes";
import { headerConfig } from "@/constants/pageConfig";
import GovLogoSvg from "@/assets/images_local/logo-singapore-gov.svg";
import additionalDirectives from "@/utils/additionalDirectives.js";
import imageActions from "@/utils/imageActions";
import { MomLayoutWrapper, MomHorizontalLine } from "cdpl/layout.esm";
import { MomIcon } from "cdpl/icon.esm";
import { MomInputText } from "cdpl/input_text.esm";
import { MomButton } from "cdpl/button.esm";

import ChevronUpSvg from "@/assets/icons_local/icon-chevron-up.svg";
import ChevronDownSvg from "@/assets/icons_local/icon-chevron-down.svg";
import LockSvg from "@/assets/icons_local/icon-lock.svg";
import TownHallSvg from "@/assets/icons_local/icon-govt.svg";
import PopOutSvg from "@/assets/icons_local/icon-open-in-new.svg";

export default {
  name: "DxpPageHeader",
  release: "0.1.1",
  lastUpdated: "0.1.1",
  components: {
    MomLayoutWrapper,
    MomIcon,
    MomHorizontalLine,
    MomInputText,
    MomButton,
  },
  mixins: [imageActions],
  props: {
    /**
     * List of header tabs
     */
    tabs: {
      type: Array,
    },
    /**
     * Active Tab Index
     */
    activeTabIndex: {
      type: [Number, String],
      default: 1,
    },
    /**
     * More Options
     */
    moreOptions: {
      type: Object,
    },
  },
  data() {
    return {
      showMore: false,
      showSearchOption: false,
      searchValue: "",
      isExpandedIdentifyPanel: false,
    };
  },
  computed: {
    moreTabIndex() {
      return headerConfig.headerTabMoreIndex;
    },
    GovLogo() {
      return DOMPurify.sanitize(GovLogoSvg).replace(
        "<svg",
        "<svg focusable='false'"
      );
    },
    activeTabIndexNumber() {
      return typeof this.activeTabIndex === "number"
        ? this.activeTabIndex
        : Number.parseInt(this.activeTabIndex);
    },
    ChevronDown() {
      return DOMPurify.sanitize(ChevronDownSvg).replace(
        "<svg",
        "<svg focusable='false'"
      );
    },
    ChevronUp() {
      return DOMPurify.sanitize(ChevronUpSvg).replace(
        "<svg",
        "<svg focusable='false'"
      );
    },
    LockIcon() {
      return DOMPurify.sanitize(LockSvg).replace(
        "<svg",
        "<svg focusable='false'"
      );
    },
    TownHall() {
      return DOMPurify.sanitize(TownHallSvg).replace(
        "<svg",
        "<svg focusable='false'"
      );
    },
    PopOut() {
      return DOMPurify.sanitize(PopOutSvg).replace(
        "<svg",
        "<svg focusable='false'"
      );
    },
  },
  watch: {},
  methods: {
    closeSearch() {
      this.showMore = false;
    },
    onToggleMoreMobile() {
      let mobileMenu = document.getElementsByClassName(
        "DxpPageHeader__DropdownContent--mobile"
      )[0];
      if (mobileMenu.style.display == "block")
        mobileMenu.style.display = "none";
      else mobileMenu.style.display = "block";
    },
    onToggleMore() {
      this.showMore = !this.showMore;
      /**
       * When the search bar is toggled
       *
       * @event toggle
       * @type {object}
       */
      this.$emit("toggle-more", { target: this });
    },

    onKeyDownMore(e) {
      const key = e.keyCode || e.which;
      switch (key) {
        case KEYCODES.SPACE:
        case KEYCODES.ENTER:
          e.preventDefault();
          this.onToggleMore();
          break;
      }
    },

    onToggleSearch() {
      this.showSearchOption = !this.showSearchOption;
      this.$nextTick(() => {
        document.getElementById("DxpPageHeader__SearchInput").focus();
      });

      /**
       * When the search bar is toggled
       *
       * @event toggle
       * @type {object}
       */
      this.$emit("toggle-search", { target: this });
    },

    onKeyDownSearch(e) {
      const key = e.keyCode || e.which;
      switch (key) {
        case KEYCODES.SPACE:
        case KEYCODES.ENTER:
          e.preventDefault();
          this.onToggleSearch();
          break;
      }
    },

    onSearch() {
      /**
       * When the search button is clicked
       *
       * @event search
       * @type {object}
       */
      this.$emit("search", this.searchValue);
    },

    toggleIdentifyPanel() {
      this.isExpandedIdentifyPanel = !this.isExpandedIdentifyPanel;
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
$tab-text-color: $color-blue-60;
$tab-border-bottom: rem(1) solid transparent;
$active-tab-color: $color-black;
$active-tab-border-bottom: rem(4.8) solid #b64a8a;

.DxpPageHeader {
  box-shadow: $box-shadow-header;
  background-color: $color-white;

  @media print {
    box-shadow: none;
  }
}

.DxpPageHeader--is-sticky {
  position: relative;
  width: 100%;
  z-index: $z-index-sticky;
  box-shadow: $box-shadow-header;

  @media print {
    position: relative;
  }

  @include min-width(tablet) {
    position: fixed;
    top: 0;
    left: 0;
  }
}

.DxpPageHeader__Logo {
  margin: rem(0) 0;
  margin-left: rem(-20);

  @include min-width(tablet) {
    margin: rem(0) 0;
  }
}

.DxpPageHeader__LogoName {
  width: rem(120);
  height: rem(60);
  margin-top: rem(8) !important;
  margin-left: rem(8) !important;
}

.DxpPageHeader__MastheadWrapper {
  background-color: $color-cool-grey-15;
  padding: rem(4) 0;

  @media print {
    display: none;
  }
}

.DxpPageHeader__Masthead {
  display: flex;
  padding-left: rem(24);
  @include min-width(tablet) {
    padding-left: rem(32);
  }
}

.DxpPageHeader__MastheadLink {
  display: inline-flex;
  text-decoration: none;

  figure {
    flex: 0 0 auto;
    margin: 0;
  }
}

.DxpPageHeader__MastheadDescriptor {
  @include font-size(s);
  @include line-height(s);
  margin-left: rem(8);
  color: $color-cool-grey-100;
}

.DxpPageHeader__MainWrapper {
  position: relative;
}

.DxpPageHeader__Main {
  display: flex;
  justify-content: space-between;
  padding-left: rem(32);
  padding-right: rem(32);
  figure {
    margin: rem(0);
    margin-left: -0.75rem;
  }
}

.DxpPageHeader__Tabs {
  flex: 1;

  &--tablet {
    display: none;

    @include min-width(tablet) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-left: rem(144);
    }
  }

  &--mobile {
    display: none;

    .DxpPageHeader--show-search-bar & {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: rem(16);

      @include min-width(tablet) {
        display: none;
      }
    }
  }
}

.DxpPageHeader__Tab {
  .DxpPageHeader__Tabs--tablet & {
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: rem(5) solid transparent;

    &:last-child {
      margin-right: 0;
    }
  }

  .DxpPageHeader__Tabs--mobile & {
    margin-top: rem(20);
  }

  &--is-active {
    .DxpPageHeader__Tabs--tablet & {
      border-bottom: rem(5) solid $color-docs-purple;
    }
  }
}

.DxpPageHeader__Dropdown {
  position: relative;
}

.DxpPageHeader__DropdownToggle {
  display: flex;
  flex-direction: row;
}

.DxpPageHeader__DropdownToggle--mobile {
  margin: rem(18) rem(0);
  @include font-size(m);
  @include line-height(m);
  @include font-weight(semibold);
  color: $color-black;
}

.DxpPageHeader__DropdownContent--mobile {
  display: none;

  @include min-width(tablet) {
    display: none;
  }
}

.DxpPageHeader__DropdownContent {
  display: none;

  .DxpPageHeader__Dropdown--show-content & {
    display: flex;
    flex-direction: column;
    min-width: rem(150);

    .DxpPageHeader__Tabs--tablet & {
      position: absolute;
      top: rem(80);
      right: 0;
      background-color: $color-white;
      z-index: $z-index-dropdown;
      box-shadow: $box-shadow-xl;
      padding: rem(8) rem(12);
      border-radius: $border-radius-corner;
    }
  }
}

.DxpPageHeader__DropdownLink {
  margin-top: rem(20);
  &:first-child {
    .DxpPageHeader__Tabs--tablet & {
      margin-top: 0;
    }
  }

  .DxpPageHeader__Tabs--mobile & {
    margin-left: rem(20);
  }
}

.DxpPageHeader__DropdownToggle,
.DxpPageHeader__Link {
  @include font-size(m);
  @include line-height(m);
  @include font-weight(semibold);
  color: $color-black;

  &:active,
  &:visited {
    color: $color-black;
  }
  &:hover {
    color: #B74B8A !important;
    .MomIcon {
      color: #B74B8A !important;
    }
  }
}

.DxpPageHeader__Search {
  display: flex;
  align-items: center;
  margin-left: rem(24);
  margin-bottom: rem(5);
}

.DxpPageHeader__SearchBar {
  display: none;
  transition-property: padding, opacity;
  transition-duration: $transition-duration-s;
  transition-timing-function: $transition-timing-function-ease;

  .DxpPageHeader--show-search-bar & {
    display: flex;
    justify-content: flex-end;
    padding-top: rem(18);
    padding-bottom: rem(18);
  }
}

.DxpPageHeader__Separator {
  margin: rem(0) !important;
  display: none;

  .DxpPageHeader--show-search-bar & {
    display: block;
  }
}

.DxpPageHeader__SearchInput {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.DxpPageHeader__SearchButton {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;

  &--tablet {
    display: none !important;

    @include min-width(tablet) {
      display: flex !important;
    }
  }

  &--mobile {
    display: flex !important;

    @include min-width(tablet) {
      display: none !important;
    }
  }
}

.DxpPageHeader__SearchToggle {
  &--mobile {
    display: flex !important;

    @include min-width(tablet) {
      display: none !important;
    }
  }

  &--tablet {
    display: none !important;

    @include min-width(tablet) {
      display: flex !important;
    }
  }

  &:hover {
    color: #B74B8A !important;
  }
}

.DxpPageHeader__TabChild {
  margin-left: rem(18);
  margin-top: rem(18);

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: rem(18);
  }
}

.MomPageHeader__MastheadWrapper {
  background-color: #f4f4f4;
  padding: rem(4) 0;

  @media print {
    display: none;
  }
}

.MomPageHeader__Masthead {
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  
  @media (max-width: 392px) {
    padding-left: 8px;
    padding-right: 8px;
  }

  @include min-width(tablet) {
    padding-left: 32px;
    padding-right: 32px;
  };
}

.MomPageHeader__MastheadLink {
  display: inline-flex;
  text-decoration: none;
  color: $color-blue-80;

  figure {
    flex: 0 0 auto;
    margin: 0;
  }

  &:hover {
    color: $color-blue-90 !important;
    cursor: pointer;
    .ChevronStyle {
    color: $color-blue-90;
    }
  }
}

.MastheadDescriptor {
  color: $color-cool-grey-100;
  margin-left: 4px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;

  @include min-width(tablet) {
    margin-left: 8px;
    font-size: 16px;
    line-height: 24px;
  };
}
.GovExpandedHeader {
  padding-left: 12px;
  padding-right: 12px;

  @include min-width(tablet) {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
  };
}
.IdentifyLink {
  color: $color-blue-80;
  margin-left: 4px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  white-space: nowrap;
  align-self: center;

  &:hover {
    color: $color-blue-90;
  }

  @include min-width(tablet) {
    margin-left: 8px;
    margin-right: 4px;
    font-size: 16px;
    line-height: 24px;
  };
}

.ChevronStyle {
  display: inline-flex;
  width: 16px;
  height: 16px;
  align-self: center;
  align-items: center;
  color: $color-blue-80;

  @include min-width(tablet) {
    width: 18px;
    height: 18px;
  };
}

.GovExpandedFont {
  font-size: 16px; 
  font-family: sans-serif;
  display: inline-block;
}

.GovExpandedLinkFont {
  font-size: 16px; 
  font-family: sans-serif;
  display: inline-block;
  color: $color-blue-80;

  &:visited {
    color: $color-purple-90;
  }

  &:hover {
    color: $color-blue-90 !important;
  }

}

.GovExpandedDiv {
  display: flex; 
  margin-bottom: 32px;
  @include min-width(tablet) {
    width: 50%;
  }
}

.BoldFont {
  font-weight: 600;
}

.DisplayBlock {
  display: block;
}
</style>
