<template>
  <div
    v-if="totalImages > 0"
    :class="['DxpCardCarousel']"
    :style="{
      width: `${componentWidth}rem;`,
    }"
  >
    <div class="DxpCardCarousel__PreviewContainer">
      <div class="DxpCardCarousel__Wrapper">
        <div :class="['DxpCardCarousel__Image']" ref="image">
          <mom-grid-container class="DxpCardCarousel__ImageWrapper">
            <mom-grid-column size-lg="7" size="12">
              <div class="DxpCardCarousel__Content">
                <div>
                  <h1 class="mom-h1">{{ currentImage.title }}</h1>
                  <p class="DxpCardCarousel__Content__Text">
                    {{ currentImage.text }}
                  </p>
                  <mom-button
                    class="DxpButton"
                    @click="$router.push(currentImage.link)"
                    :text="currentImage.buttonLabel"
                  />
                </div>
              </div>
            </mom-grid-column>
            <mom-grid-column size-lg="5" size="12">
              <div v-if="currentImage.url">
                <figure
                  v-if="isSvg"
                  class="DxpCardCarousel__Image"
                  id="carousel_image_current"
                  v-html="currentImage.url"
                  :style="{
                    transform: `scale(${scale}) translate(${translateX}px, ${translateY}px)`,
                  }"
                  @click="$router.push(currentImage.link)"
                ></figure>
                <img
                  v-else
                  class="DxpCardCarousel__Image"
                  id="carousel_image_current"
                  :src="currentImage.url"
                  :alt="currentImage.alt"
                  :style="{
                    transform: `scale(${scale}) translate(${translateX}px, ${translateY}px)`,
                  }"
                  @click="$router.push(currentImage.link)"
                />
              </div>
              <div v-else class="DxpCardCarousel__Image__SpinnerContainer">
                <MomSpinner /></div
            ></mom-grid-column>
          </mom-grid-container>
        </div>
      </div>

      <button
        v-if="currentImageIndex > 0"
        class="DxpCardCarousel__PrevButton"
        @click="updateImage(currentImageIndex - 1)"
      >
        <mom-icon icon="chevron-left" size="l" />
      </button>

      <button
        v-if="currentImageIndex < totalImages - 1"
        class="DxpCardCarousel__NextButton"
        @click="updateImage(currentImageIndex + 1)"
      >
        <mom-icon icon="chevron-right" size="l" />
      </button>
    </div>

    <div
      v-if="currentImage.description"
      class="DxpCardCarousel__DescriptionWrapper"
    >
      <p class="mom-p">{{ currentImage.description }}</p>
    </div>
  </div>

  <div
    v-else
    class="DxpCardCarousel__Placeholder"
    :style="{
      width: `${componentWidth}rem`,
    }"
  >
    <p class="DxpCardCarousel__PlaceholderText mom-p-s">
      Only jpg and png files will display a preview.
    </p>
  </div>
</template>

<script>
/* eslint-disable */
import { MomIcon } from "cdpl/icon.esm";
import { MomSpinner } from "cdpl/spinner.esm";
import { MomButton } from "cdpl/button.esm";
import { MomGridContainer, MomGridColumn } from "cdpl/gridsystem.esm";

export default {
  name: "DxpCardCarousel",
  release: "0.1.1",
  lastUpdated: "0.1.1",
  components: {
    MomGridContainer,
    MomGridColumn,
    MomIcon,
    MomSpinner,
    MomButton,
  },
  props: {
    /**
     * Images if svg
     */
    isSvg: {
      type: Boolean,
      required: false,
    },
    /**
     * Image list <br />
     * `url` `description`, `link`, ``title, `buttonLabel`, `text`
     */
    images: {
      type: Array,
      required: true,
    },

    /**
     * Set headers
     */
    headers: {
      type: Object,
      default: () => {
        return {};
      },
    },
    /**
     * Preview container width
     */
    width: {
      type: [Number, String],
      default: 544,
    },
  },
  data() {
    return {
      currentImageIndex: 0,
      mostLeft: true,
      mostRight: true,
      scale: 1,
      translateX: 0,
      translateY: 0,
    };
  },
  computed: {
    componentWidth() {
      return this.width;
    },
    currentImage() {
      return this.images[this.currentImageIndex];
    },
    totalImages() {
      return this.images.length;
    },
  },
  watch: {
    images() {
      this.currentImageIndex = 0;
      this.$nextTick(() => {
        if (this.totalImages > 1) {
          this.updateScrollState();
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.totalImages > 1) {
        this.updateScrollState();
      }
    });
  },
  methods: {
    updateImage(i) {
      this.$refs.image.classList.add("DxpCardCarousel__Image--hidden");
      setTimeout(() => {
        this.currentImageIndex = i;
        this.scale = 1;
        this.translateX = 0;
        this.translateY = 0;
        this.$refs.image.classList.remove("DxpCardCarousel__Image--hidden");
      }, 250);
    },

    updateScrollState() {
      this.mostLeft = true;
      this.mostRight = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.DxpCardCarousel {
  max-width: 100%;
  height: rem(448);
  @include min-width(tablet) {
    height: rem(160);
  }
}

.DxpCardCarousel__PreviewContainer {
  width: 100%;
  position: relative;
}

.DxpCardCarousel__Wrapper {
  height: 100%;
  background-color: $color-white;
}

.DxpCardCarousel__Image {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity $transition-duration-m $transition-timing-function-ease;
  overflow: hidden;
  background-color: $color-white;
  justify-content: center;

  > img {
    margin: auto;
    width: auto;
    height: auto;
    max-width: 50%;
    max-height: 100%;
  }

  &--hidden {
    opacity: 100;
  }
}

.DxpCardCarousel__DescriptionWrapper {
  width: calc(100% - #{rem(96)});
  margin-left: rem(48);
  margin-right: rem(48);
  height: rem(48);
  margin-top: rem(16);
  overflow: auto;
  word-break: break-word;
}

.DxpCardCarousel__PrevButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  color: $color-cool-grey-90;
  padding: rem(8);
  outline-offset: 0;
}

.DxpCardCarousel__NextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  color: $color-cool-grey-90;
  padding: rem(8);
  outline-offset: 0;
}

.DxpCardCarousel__Placeholder {
  max-width: 100%;
  background-color: $color-cool-grey-05;
  display: flex;
  align-items: center;
  justify-content: center;
  height: rem(448);

  .DxpCardCarousel__PlaceholderText {
    color: $color-cool-grey-50;
  }

  @include min-width(tablet) {
    height: rem(160);
  }
}

.DxpCardCarousel__Image__SpinnerContainer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
}

.DxpCardCarousel__Content__Text {
  width: 70%;
  padding-bottom: rem(32);
}

.DxpCardCarousel__ImageWrapper {
  align-items: center;
}
</style>
