function getTitle(vm) {
  const { title } = vm.$options;
  if (title) {
    return typeof title === "function" ? title.call(vm) : title;
  }
}

function getMetaDescription(vm) {
  const { description } = vm.$options;
  if (description) {
    return typeof description === "function"
      ? description.call(vm)
      : description;
  }
}

import Vue from "vue";
Vue.mixin({
  created() {
    const title = getTitle(this);
    const description = getMetaDescription(this);
    if (title) {
      document.title = title;
    }

    if (description) {
      let metaDesc = document.querySelector('meta[name="description"]');
      if (metaDesc) metaDesc.setAttribute("content", description);
    }
  },
});
