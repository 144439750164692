<template>
  <ul class="DxpNavBar__List">
    <li
      class="DxpNavBar__ListItem"
      v-for="(item, i) in itemList"
      :key="`navbar_item_${i}`"
      :id="`navbar_item_${i}`"
    >
      <a
        :class="[
          'DxpNavBar__ListItemLink',
          item.isActive && 'DxpNavBar__ListItemLink--active',
        ]"
        @click="onClick($event, item)"
        :href="item.href"
        >{{ item.title }}</a
      >
      <dxp-nav-bar
        v-if="item.isActive && item.children && item.children.length > 0"
        :item-list="item.children"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: "DxpNavBar",
  release: "0.1.1",
  lastUpdated: "0.1.1",
  props: {
    /**
     * List of items in navigation bar
     */
    itemList: {
      type: Array,
    },
  },
  components: {},
  methods: {
    onClick(e, item) {
      /**
       * When the button is clicked
       * @event click
       * @type {object}
       */
      this.$emit("click", e, item);
    },
  },
};
</script>

<style lang="scss" scoped>
$bg-color: $color-white;
$border: rem(5) solid transparent;
$active-bg-color: $color-docs-purple-3;
$active-border: rem(5) solid $color-docs-purple;
$margin-sublist: rem(25);

.DxpNavBar__List {
  :hover {
    color: $color-docs-purple;
  }
  // children list
  .DxpNavBar__List & {
    margin-left: $margin-sublist;

    .DxpNavBar__ListItemLink--active {
      color: $color-docs-purple;
      background-color: $bg-color;
      border-left: $border;
    }
  }
}

.DxpNavBar__ListItemLink {
  @include font-weight(semibold);
  display: block;
  padding: rem(19.2) rem(18);
  padding-right: rem(2);
  text-decoration: none;
  background-color: $bg-color;
  border-left: $border;
  color: $color-cool-grey-70;

  &--active {
    background-color: $active-bg-color;
    border-left: $active-border;
  }
}
</style>
