/**
 * @mixin
 * Define list of available actions for loading image
 */

export default {
  methods: {
    openInNewTab(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    openInSameTab(url) {
      window.open(url, "_self");
    },
    checkIfSameRoute(link) {
      /* Use if possibility of routing to same page link - to scroll back to top of page */
      let currentLink = "#" + this.$route.path;
      if (link == currentLink) document.getElementById("app").scrollIntoView();
      return link;
    },
  },
};
