<template>
  <div class="DxpPageFeedback">
    <mom-layout-wrapper class="DxpPageFeedback__LayoutWrapper">
      <div class="DxpPageFeedback__Card">
        <h2 class="DxpPageFeedback__CardTitle mom-h2">
          {{ title || "Got any comments and feedback?" }}
        </h2>
        <div>
          <mom-button
            class="DxpPageFeedback__CardButton DxpButton"
            :text="buttonText || 'Visit our community'"
            @click="onClick"
          />
        </div>
      </div>
    </mom-layout-wrapper>
  </div>
</template>
<script>
import { MomLayoutWrapper } from "cdpl/layout.esm";
import { MomButton } from "cdpl/button.esm";

export default {
  name: "DxpPageFeedback",
  components: {
    MomLayoutWrapper,
    MomButton,
  },
  props: {
    title: {
      type: String,
    },
    buttonText: {
      type: String,
    },
  },
  methods: {
    /**
     * When the feedback button is clicked
     *
     * @event click
     * @type {event}
     */
    onClick(e) {
      this.$emit("click", e);
    },
  },
};
</script>
<style lang="scss" scoped>
$card-bg: $color-white;

.DxpPageFeedback {
  background-image: url("../../assets/images_local/feedback_bg.png");
  background-size: cover;
}

.DxpPageFeedback__LayoutWrapper {
  padding: rem(45) rem(10);

  @include min-width(tablet) {
    padding: rem(45) rem(0);
  }
}

.DxpPageFeedback__Card {
  padding: rem(30) rem(20);
  background-color: $color-white;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: $box-shadow-m;

  @include min-width(tablet) {
    padding: rem(50) rem(36);
    margin: rem(0) rem(32);
    flex-direction: row;
  }
}

.DxpPageFeedback__CardTitle {
  flex: 1;
  @include min-width(tablet) {
    margin-bottom: rem(0);
  }
}
</style>
