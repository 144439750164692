export default {
  BKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  CAPSLOCK: 20,
  ESC: 27,
  SPACE: 32,
  PGUP: 33,
  PGDOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  INSERT: 46,
  DELETE: 46,
  META1: 91,
  META2: 91,
  META3: 91,
}
